<section class="hero-banner d-flex align-items-center">
    <div class="container text-center ">
        <a asp-action="product">
            <h2 style="font-weight: 500" class="text-center text-white">
                PRODATA PRODUCTS
            </h2>
        </a>
    </div>
</section>

<section class="service-area pb-5">
    <div class="container">
        <div data-aos="fade-up">
        <div class="row mt-5">
            <!-- 1 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service">
                    <div class="service-icon">
                        <img src="assets/img/icon/banking.png" class="center mb-5 ml-3" height="45px" width="45px" alt="Alternate Text" />
                    </div>
                    <div class="service-content">
                        <h5>AGENT BANKING</h5>
                        <p>
                            Online Based Agent Terminal
                            Four Step Security
                            <br /> <span style="color: #00C8FF">&#10004;</span> Bank Account, Biometric identification
                            <br /> <span style="color: #00C8FF">&#10004;</span> SMS Notification, Paper slip
                            Available Services
                            <br /> <span style="color: #00C8FF">&#10004;</span> Account Opening, Cash Deposit, Cash Withdraw
                            <br /> <span style="color: #00C8FF">&#10004;</span> Remitance Disbursement, P2P , P2B, Merchant Payment
                            <br /> <span style="color: #00C8FF">&#10004;</span> Cheque Book Requisition, Deposit Requisition
                            <br /> <span style="color: #00C8FF">&#10004;</span> Statement Enquery, Balance Enquire
                            <br /> <span style="color: #00C8FF">&#10004;</span> Utility Bill Payment
                        </p>
                    </div>
                </div>
            </div>

            <!-- 2 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service m-0">
                    <div class="service-icon">
                        <img src="assets/img/icon/mobile-bank.png" class="mb-4 ml-3" style="width:45px; height:45px" alt="Alternate Text" />
                    </div>
                    <div class="service-content">
                        <h5>MOBILE BANKING</h5>
                        <p>
                            Supported Channel
                            <br /> <span style="color: #00C8FF">&#10004;</span> USSD, WAP, APPS, Web, IVR
                            <br /> <span style="color: #00C8FF">&#10004;</span> Multi operator SMS notification
                            <br /> <span style="color: #00C8FF">&#10004;</span> Multiple wallet against single mobile number
                            Available Services
                            <br /> <span style="color: #00C8FF">&#10004;</span> Open Mobile Wallet, Balance enquiry, Mini Statement
                            <br /> <span style="color: #00C8FF">&#10004;</span> Fund Tranfer, P2P , P2B, Merchant Payment
                            <br /> <span style="color: #00C8FF">&#10004;</span> Mobile Deposit, Mobile Top Up, Mobile Salary
                            <br /> <span style="color: #00C8FF">&#10004;</span> Utility Bill Payment
                        </p>
                    </div>
                </div>
            </div>


            <!-- 3 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service">
                    <div class="service-icon">
                        <img src="assets/img/icon/internet-banking.png" class="mb-4 ml-3" style="width:45px; height:45px" alt="Alternate Text" />
                    </div>
                    <div class="service-content">
                        <h5>INTERNET & APPS BANKING</h5>
                        <p>
                            Supported Technology
                            <br /> <span style="color: #00C8FF">&#10004;</span> Responsive Web Interface
                            <br /> <span style="color: #00C8FF">&#10004;</span> All Device compitable
                            <br /> <span style="color: #00C8FF">&#10004;</span> Native Mobile Apps for Androaid, IOS & Windows
                            <br /> <span style="color: #00C8FF">&#10004;</span> SMS and Device Base OTP Engine
                            Available Services
                            <br /> <span style="color: #00C8FF">&#10004;</span> Account Summary, Account Details, Pin Chnange
                            <br /> <span style="color: #00C8FF">&#10004;</span> Account Statement, Balance enquiry, Intra Bank Transfer Fund
                            <br /> <span style="color: #00C8FF">&#10004;</span> External Bank Transfer Fund, Beneficiary management, Cheque book request
                            <br /> <span style="color: #00C8FF">&#10004;</span> Utility Bill Payment, Auto Debit Instruction
                        </p>
                    </div>
                </div>
            </div>
</div>
        </div>
    </div>


    <div class="container">
        <div data-aos="fade-up">
        <div class="row mt-5">

            <!-- 4 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service">
                    <div class="service-icon">
                        <img src="assets/img/icon/human resource.png" class="mb-4 ml-3" style="width:45px; height:45px" alt="Alternate Text" />
                    </div>
                    <div class="service-content">
                        <h5>HUMAN RESOURCE SOFTWARE (HRMS)</h5>
                        <p>
                            Available Features
                            <br /> <span style="color: #00C8FF">&#10004;</span> i-Recruitment, HR Apps
                            <br /> <span style="color: #00C8FF">&#10004;</span> Centralized Attendance (Thumb, NFC & Apps)
                            <br /> <span style="color: #00C8FF">&#10004;</span> Leave Process, Roster Management
                            <br /> <span style="color: #00C8FF">&#10004;</span> Performance Management, Payroll & Gratuity Management
                            <br /> <span style="color: #00C8FF">&#10004;</span> Advance, Claim & Loan Management
                            <br /> <span style="color: #00C8FF">&#10004;</span> HR & MIS Reports, Employee Settlement
                            <br /> <span style="color: #00C8FF">&#10004;</span> Employee Welfare, Travel Management
                            <br /> <span style="color: #00C8FF">&#10004;</span> Provident Fund, Gratuaty
                            <br /> <span style="color: #00C8FF">&#10004;</span> SMS & Email Notification
                        </p>
                    </div>
                </div>
            </div>

            <!-- 5 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service m-0">
                    <div class="service-icon">
                        <img src="assets/img/icon/supply-chain.png" class="mb-4 ml-3" style="width:45px; height:45px" alt="Alternate Text" />
                    </div>
                    <div class="service-content">
                        <h5>SUPPLY CHAIN MANAGEMNET (SCM)</h5>
                        <p>
                            Available Features
                            <br /> <span style="color: #00C8FF">&#10004;</span> Parameterized Inventory, Supplier Management
                            <br /> <span style="color: #00C8FF">&#10004;</span> Supplierwise Product Costing & Comparison
                            <br /> <span style="color: #00C8FF">&#10004;</span> Centralized Requisition Appoval
                            <br /> <span style="color: #00C8FF">&#10004;</span> Multi Workorder against signle or multi requisition
                            <br /> <span style="color: #00C8FF">&#10004;</span> Bynamic Inventory location Setup
                            <br /> <span style="color: #00C8FF">&#10004;</span> Auto Notification and Dash Boar
                        </p>
                    </div>
                </div>
            </div>

            <!-- 6 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service">
                    <div class="service-icon">
                        <img src="assets/img/icon/warehouse.png" class="mb-4 ml-3" style="width:45px; height:45px" alt="Alternate Text" />
                    </div>
                    <div class="service-content">
                        <h5>WAREHOUSE MANAGEMENT</h5>
                        <p>
                            Available Features
                            <br /> <span style="color: #00C8FF">&#10004;</span> Multi Warehouse Management From Single Point
                            <br /> <span style="color: #00C8FF">&#10004;</span> Bulk Excell Upload for Stock
                            <br /> <span style="color: #00C8FF">&#10004;</span> Auto FIFO stock out
                            <br /> <span style="color: #00C8FF">&#10004;</span> Auto Locate Physical Location
                        </p>
                    </div>
                </div>
            </div>
</div>
        </div>
    </div>

    <div class="container">
        <div data-aos="fade-up">
        <div class="row mt-5">

            <!-- 7 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service">
                    <div class="service-icon">
                        <img src="assets/img/icon/erp.png" class="mb-4 ml-3" style="width:45px; height:45px" alt="Alternate Text" />
                    </div>
                    <div class="service-content">
                        <h5>ERP FOR MANUFACTURING</h5>
                        <p>
                            Available Features
                            <br /> <span style="color: #00C8FF">&#10004;</span> Dynamic Product Setup
                            <br /> <span style="color: #00C8FF">&#10004;</span> Production Planning
                            <br /> <span style="color: #00C8FF">&#10004;</span> Production Controlling & QC
                            <br /> <span style="color: #00C8FF">&#10004;</span> Supply Chain Management
                            <br /> <span style="color: #00C8FF">&#10004;</span> HR-Payroll
                            <br /> <span style="color: #00C8FF">&#10004;</span> Accounting
                            <br /> <span style="color: #00C8FF">&#10004;</span> Fixed Asset
                        </p>
                    </div>
                </div>
            </div>

            <!-- 8 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service m-0">
                    <div class="service-icon">
                        <img src="assets/img/icon/erp.png" class="mb-4 ml-3" style="width:45px; height:45px" alt="Alternate Text" />
                    </div>
                    <div class="service-content">
                        <h5>ERP FOR HOSPITAL</h5>
                        <p>
                            Available Features
                            <br /> <span style="color: #00C8FF">&#10004;</span> Patient Management
                            <br /> <span style="color: #00C8FF">&#10004;</span> Doctors Management
                            <br /> <span style="color: #00C8FF">&#10004;</span> Pharmacy Management
                            <br /> <span style="color: #00C8FF">&#10004;</span> OT Management
                            <br /> <span style="color: #00C8FF">&#10004;</span> Administration & HR
                            <br /> <span style="color: #00C8FF">&#10004;</span> Accounting (GL)
                            <br /> <span style="color: #00C8FF">&#10004;</span> Fixed Asset
                        </p>
                    </div>
                </div>
            </div>

            <!-- 9 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service">
                    <div class="service-icon">
                        <img src="assets/img/icon/erp.png" class="mb-4 ml-3" style="width:45px; height:45px" alt="Alternate Text" />
                    </div>
                    <div class="service-content">
                        <h5>ERP FOR APPERAL</h5>
                        <p>
                            Available Features
                            <br /> <span style="color: #00C8FF">&#10004;</span> Merchandise
                            <br /> <span style="color: #00C8FF">&#10004;</span> Production Planning
                            <br /> <span style="color: #00C8FF">&#10004;</span> Production Controlling & QC
                            <br /> <span style="color: #00C8FF">&#10004;</span> Supply Chain Management
                            <br /> <span style="color: #00C8FF">&#10004;</span> HR-Payroll
                            <br /> <span style="color: #00C8FF">&#10004;</span> Accounting
                            <br /> <span style="color: #00C8FF">&#10004;</span> Fixed Asset
                        </p>
                    </div>
                </div>
            </div>
</div>
        </div>
    </div>

</section>

