<section class="hero-banner d-flex align-items-center">
    <div class="container text-center">
        <a asp-action="Service">
            <h2 style="font-weight: 500" class="text-center text-white">
                PRODATA SERVICES
            </h2>
        </a>
    </div>
</section>

<section class="service-area pb-5">
    <div class="container">
        <div data-aos="fade-up">
        
        <div class="row mt-5">
            <!-- 1 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service">
                    <div class="service-icon">
                        <i class="fab fa-mixcloud"></i>
                    </div>
                    <div class="service-content">
                        <h5>Cloud ERP</h5>
                        <p>
                            Prodata Cloud ERP is an enterprise resource planning (ERP) and management solution designed to meet the requirements of midsize and large enterprise businesses.
                            The solution primarily assists companies with accounting, human resource and inventory management and business intelligence functionalities.
                        </p>
                    </div>
                </div>
            </div>

            <!-- 2 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service m-0">
                    <div class="service-icon">
                        <img src="../../../../assets/img/icon/life-insurance.png" class="mb-4 ml-3" style="width:45px; height:45px" alt="Alternate Text" />
                    </div>
                    <div class="service-content">
                        <h5>Insurance Agent Management Solution Apps</h5>
                        <p>
                            Using the Prodata Insurance Agent Management Solution Apps solution insurance company will be able to control the business centrally.
                            Insurance agent will be able to offer new insurance proposal and also will be able to renew exiting insurance renew instantly.
                            This solution will enable insurance company to reduce its revenue risk also enable better control over agents countrywide.
                        </p>
                    </div>
                </div>
            </div>


            <!-- 3 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service">
                    <div class="service-icon">
                        <i class="fas fa-qrcode ml-4"></i>
                    </div>
                    <div class="service-content">
                        <h5>QR Payment</h5>
                        <p>
                            QR Pay is a recent feature of payment gateway in which the Direct Payment Link has been upgraded.
                            Merchants can generate QR (Quick Response) code which they can send to their customers to expedite payments via mobile devices.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>


    <div class="container">
        <div data-aos="fade-up">
        <div class="row mt-5">

            <!-- 4 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service">
                    <div class="service-icon">
                        <i class="far fa-comment-alt"></i>
                    </div>
                    <div class="service-content">
                        <h5>Bulk SMS</h5>
                        <p>
                            Prodata Bulk SMS service lets you send SMS to a range of networks in each country.
                            SMS can be send directly from your PC, web account or using API.
                            ALL SMS service aims to provide a standard platform to allow clients in Bangladesh to quickly deploy any SMS service.
                            This includes SMS alerts, SMS marketing campaigns or SMS for CRM.
                        </p>
                    </div>
                </div>
            </div>

            <!-- 5 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service m-0">
                    <div class="service-icon">
                        <i class="far fa-chart-bar"></i>
                    </div>
                    <div class="service-content">
                        <h5>Sales Force Tracking & Order Collection Apps</h5>
                        <p>Sales Force Tracking & Order Collection Apps offers on field sales employee GPS location tracking, attendance system, sales visit tracking, sales order and payment collection data logging via mobile app connected to web app.</p>
                    </div>
                </div>
            </div>

            <!-- 6 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service">
                    <div class="service-icon">
                        <img src="assets/img/icon/queue.png" class="mb-4 ml-3" style="width:45px; height:45px" alt="Alternate Text" />
                    </div>
                    <div class="service-content">
                        <h5>Queue Management Solution (QMS)</h5>
                        <p>ALL queue management software helps you organize your waiting lines by providing visitors with an easy ordering method based on first in first out, prescheduled appointments, and more sophisticated methods. It is perfect solution for any kind od customer services provider (i.e. Bank, Hospital, Insurance etc)</p>
                    </div>
                </div>
            </div>
</div>
        </div>
    </div>

    <div class="container">
        <div data-aos="fade-up">
        <div class="row mt-5">

            <!-- 7 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service">
                    <div class="service-icon">
                        <i class="fab fa-internet-explorer"></i>
                    </div>
                    <div class="service-content">
                        <h5>Online Recruitment</h5>
                        <p>To secure the right candidate you need a strategic hiring solution that seamlessly combines expert knowledge, cutting edge technology and world class customer service. That's us.As leading innovators in online recruitment solution provider, we consistently outperform traditional recruitment agencies. We work in partnership with you, integrating our proven methods into your existing in-house recruitment process, saving you time and money.</p>
                    </div>
                </div>
            </div>

            <!-- 8 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service m-0">
                    <div class="service-icon">
                        <i class="fas fa-shopping-cart"></i>
                    </div>
                    <div class="service-content">
                        <h5>E-Commerce Hosting Portal</h5>
                        <p>We are providing all-in-one e-commerce platform at on price that include online store, hosting and payment gateway. Managing server and the teams can cost you thounsands of money per year and take away from the time you could be running you business.</p>
                    </div>
                </div>
            </div>

            <!-- 9 -->
            <div class="col-md-6 col-lg-4">
                <div class="single-service">
                    <div class="service-icon">
                        <i class="fas fa-network-wired"></i>
                    </div>
                    <div class="service-content">
                        <h5>IT Manage Service</h5>
                        <p>We provide end to end IT manamge services including System Integration, Data Cenetr and Network Support, DBA and IT procurement. We speak the language of your business and translating our IT services into terms that are understandable, empowering and, ultimately, transformative. Trust, honesty and open communication are more than just words. it is the foundation of our exceptional IT support services and proven results.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
</section>
