
<section class="hero-banner d-flex align-items-center">
    <div class="container text-center ">
        <a asp-action="product">
            <h2 style="font-weight: 500" class="text-center text-white">
               CONTACT PRODATA
            </h2>
        </a>
    </div>
</section>

<!-- ================ contact section start ================= -->
<section class="contact-section area-padding">
    <div class="container">
        <div data-aos="fade-up">

        <div class="row">
            <div class="col-12">
                <h2 class="contact-title">Send Message.</h2>
            </div>
            <div class="col-lg-8">
                <form  [formGroup]="contactForm"  class="form-contact contact_form" (ngSubmit)="sendEmail()" >
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <textarea required class="form-control w-100" formControlName='message'
                                 name="message" id="message" cols="30" rows="9" placeholder="Enter Message"
                                 [ngClass]="{'is-invalid': formErrors.message}" (blur)='logValidationErrors()'></textarea>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input required class="form-control" formControlName='name' name="name" id="name" type="text" placeholder="Enter your name"
                                [ngClass]="{'is-invalid': formErrors.name}"  (blur)='logValidationErrors()'>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="form-group">
                                <input required class="form-control" formControlName='email' name="email" id="email" type="email" placeholder="Enter email address"
                                [ngClass]="{'is-invalid': formErrors.email}"  (blur)='logValidationErrors()'>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="form-group">
                                <input required class="form-control" formControlName='subject' name="subject" id="subject" type="text" placeholder="Enter Subject"
                                [ngClass]="{'is-invalid': formErrors.subject}"  (blur)='logValidationErrors()'>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mt-3">
                        <button type="submit" [disabled]="!contactForm.valid" class="button button-contactForm">Send Message</button>
                    </div>
                </form>
            </div>

            <div class="col-lg-4">
                <div class="media contact-info">
                    <span class="contact-info__icon"><i class="fas fa-map-marked-alt"></i></span>
                    <div class="media-body">
                        <h3>87 Rashed Khan Menon Road</h3>
                        <p>Banglamotor, Dhaka-1217, Bangladesh.</p>
                    </div>
                </div>
                <div class="media contact-info">
                    <span class="contact-info__icon"><i class="fas fa-mobile-alt"></i></span>
                    <div class="media-body">
                        <h3>(+8801535-820840)</h3>
                        <p>Sun to Thu 10am to 6pm</p>
                    </div>
                </div>
                <div class="media contact-info">
                    <span class="contact-info__icon"><i class="fas fa-envelope-open-text"></i></span>
                    <div class="media-body">
                        <h3>info@prodata.com.bd</h3>
                        <p>Send us your query anytime!</p>
                    </div>
                </div>
            </div>
    </div></div>
</div>
</section>
<!-- ================ contact section end ================= -->