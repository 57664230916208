
    <!-- ================ start footer Area ================= -->
    <footer class="footer-area">
        <div class="container">
            <div data-aos="fade-up">

            <div class="row">

                <div class=" col-sm-8 mb-4 mb-xl-0 single-footer-widget">
                    <h4>About Us</h4>
                    <p style="color: #F7F9F9">
                        Prodata Limited is a strictly quality driven Software Development & Support,
                        System Integration and related IT Services specialist with major domain focus on the Financial Services & general enterprises.
                        With unique combination of visionary leadership, experienced resources,
                        intelligen processes and compatible infrastructure,Prodata is committed to solve business challenges,
                        and promote innovation through strong business alliances with Global Leading Innovative Technology Solution Providers,
                        and offering wide range of solution / service spectrum such as Mobile Banking, QR Payment, Insurance Agenet Apps, Sales Force Tracking, HRM etc.
                    </p>
                </div>

                <div class="col-sm-4 mb-4 mb-xl-0 single-footer-widget">
                    <h4>Contact Info</h4>
                    <div class="footer-address">
                        <p class="text-info">
                            Corporate Office : <br />Suite #5, Level #13, 218 Elephant Road (Sahera Tropical Center) Dhaka-1205, Bangladesh.
                        </p>
                        <p class="text-info">
                            Office Address : <br /> 87 Rashed Khan Menon Road
                            Banglamotor, Dhaka-1217, Bangladesh.
                        </p>
                        <span class="text-info">Phone : (+8801535-820840)</span>
                        <span class="text-info">Email : (info@prodata.com.bd)</span>
                    </div>
                </div>
            </div>

            <div class="footer-bottom row align-items-center text-center text-lg-left no-gutters text-light">
                <p class="footer-text m-0 col-lg-8 col-md-12 text-light">
                    Copyright  ProData Limited &#169;
                    {{year | date:"YYYY"}} All rights reserved.
                </p>
                <div class="col-lg-4 col-md-12 text-center text-lg-right footer-social ">
                    <a href="https://www.facebook.com/prodata.com.bd/" target="block"> <i class="fab fa-facebook-square text-light" style="font-size:25px"></i></a>
                </div>
            </div>
        </div>
    </div>
    </footer>
    <!-- ================ End footer Area ================= -->