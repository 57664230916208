import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';
import * as AOS from 'aos';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    AOS.init();
  }

  customOptions: OwlOptions = {
    loop:true,
    margin:10,
    dots: false,
    responsive:{
        0:{
            items:1,
            nav:true
        },
        400:{
            items:3,
            nav:false
        },
        800:{
            items:5,
            nav:true,
            loop:false
        }
    }
  }
}
