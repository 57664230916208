import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import * as AOS from 'aos';
import { SendEmailService } from '../../services/sendEmail/send-email.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup; 
  constructor(private fb: FormBuilder, private _emailService: SendEmailService, private http: HttpClient,
    private toastr: ToastrService) { }

  formErrors = {
    'message': '',
    'name': '',
    'email': '',
    'subject': '',
  };

  validationMessages = {
    'message': {
      'required': ''
    },
    'name': {
      'required': '',
    },
    'email': {
      'required': '',
    },
    'subject': {
      'required': '',
    }
  };


  ngOnInit(): void {
    AOS.init();
    this.formSetup();
  }

  formSetup() {
    this.contactForm = this.fb.group({
      message: ['', [Validators.required]],
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
    })
  }

  logValidationErrors(group: FormGroup = this.contactForm): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstractControl = group.get(key);
      if (abstractControl instanceof FormGroup) {
        this.logValidationErrors(abstractControl);
      } else {
        this.formErrors[key] = '';
        if (abstractControl && !abstractControl.valid
          && (abstractControl.touched || abstractControl.dirty)) {
          const messages = this.validationMessages[key];
          for (const errorKey in abstractControl.errors) {
            if (errorKey) {
              this.formErrors[key] += messages[errorKey] + ' ';
            }
          }
        }
      }
    });
  }

  sendEmail(){
    
    const emailForm = {
      "name": this.contactForm.get("name").value,
      "email": this.contactForm.get("email").value,
      "subject": this.contactForm.get("subject").value,
      "message": this.contactForm.get("message").value,
    } 
    
    this._emailService.sendEmail(this.contactForm.value).subscribe(successData =>{
      this.toastr.success('Send Email Successfully.', 'Success!');
    },error =>{
      this.toastr.error('Send Email Fail.', 'Fail!');
    })
  }

}

