<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container">

    <a class="navbar-brand" routerLink=''>
      <img src="../../../../assets/img/logo/prodata_logo_update2.png" style="width:150px" alt="">
    </a>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
      <div class="navbar-nav ml-auto">
        <a class="nav-item nav-link" routerLinkActive='active' routerLink='home'>Home</a>
        <a class="nav-item nav-link" routerLinkActive='active' routerLink='services'>Services</a>
        <a class="nav-item nav-link" routerLinkActive='active' routerLink='products'>Products</a>
        <!-- <a class="nav-item nav-link" routerLink='galle'>Gallery</a> -->
        <a class="nav-item nav-link" routerLinkActive='active' routerLink='contact'>Contact</a>
      </div>
    </div>
    
</div>
  </nav>