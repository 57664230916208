import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { CommonComponent } from './view/_layout/common/common.component';
import { HomeComponent } from './view/pages/home/home.component';
import { OurServicesComponent } from './view/pages/our-services/our-services.component';
import { OurProductsComponent } from './view/pages/our-products/our-products.component';
import { ContactComponent } from './view/pages/contact/contact.component';
import { PagenotFoundComponent } from './view/_layout/pagenot-found/pagenot-found.component';

const routes: Routes = [
  {
    path: '', component: CommonComponent, 
    children: [
      {path: 'home', component: HomeComponent},
      {path: '', redirectTo: '/home', pathMatch: 'full'},
      {path: 'services', component: OurServicesComponent},
      {path: 'products', component: OurProductsComponent},
      {path: 'contact', component: ContactComponent},
      {path: '**', component: ContactComponent},
    ]
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
