<header>
    <div class="overlay"></div>
    <video loop muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
        <source src="assets/videoBg/video.mp4" type="video/mp4">
        <!-- <source src="https://storage.googleapis.com/coverr-main/mp4/Mt_Baker.mp4" type="video/mp4"> -->
    </video>
    <div class="container h-100">
        <div class="d-flex h-100 text-center align-items-center">
            <div class="w-100 text-white">


                <div data-aos="fade-right">


                    <div class="col-lg-12">
                        <div class="banner_content text-center" style=" text-align: justify">
                            <h3 class="mb-0">ProData Limited.</h3>
                            <p style="padding:0; color: #00C8FF; font-size:18px; font-weight: 500">Software Company</p>
                            <p style="font-size:18px;">Build Your Business Modern, Faster And Reliable.</p>
                            <p id="temContent" style="font-size:18px;">
                                Our team always ready to accept your challenge. To meet your goals is our main
                                objective. Professional service, 24/7 client support, teamwork, and collaboration.
                            </p>
                            <a class="banner_btn" routerLink='contact'>Contact Us</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</header>


<section class="service-area area-padding ">
    <div class="container">

        <div class="text-center mb-5">
            <a asp-action="Service">
                <h2 style="display:inline; font-weight: 500" class="text-center mb-5 text-dark">
                    PRODATA SERVICES
                </h2>
            </a>
        </div>


        <div class="row">

            <!-- Single service -->
            <div class="col-md-6 col-lg-4">
                <div data-aos="fade-right">
                    <div class="single-service">
                        <div class="service-icon">
                            <i class="fab fa-mixcloud"></i>
                        </div>
                        <div class="service-content">
                            <h5>Cloud ERP</h5>
                            <p>Prodata Cloud ERP is an enterprise resource planning (ERP) and management
                                solution designed to meet the requirements of midsize and large enterprise businesses.
                                The solution primarily assists companies with accounting, human resource and inventory
                                management and business intelligence functionalities.</p>
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>

            <!-- Single service -->
            <div class="col-md-6 col-lg-4">
                <div data-aos="fade-up">
                    <div class="single-service m-0">
                        <div class="service-icon">
                            <img src="assets/icon/life-insurance.png" class="mb-4 ml-3" style="width:45px; height:45px"
                                alt="Alternate Text" />
                        </div>
                        <div class="service-content">
                            <h5>Insurance Agent Management Solution Apps</h5>
                            <p> Using the Prodata Insurance Agent Management Solution Apps solution insurance
                                company will be able to control the business centrally. Insurance agent will be able to
                                offer new insurance proposal and also will be able to renew exiting insurance renew
                                instantly. This solution will enable insurance company to reduce its revenue risk also
                                enable better control over agents countrywide.</p>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Single service -->
            <div class="col-md-6 col-lg-4">
                <div data-aos="fade-left">
                    <div class="single-service">
                        <div class="service-icon">
                            <i class="fas fa-qrcode ml-4"></i>
                        </div>
                        <div class="service-content">
                            <h5>QR Payment</h5>
                            <p>QR Pay is a recent feature of payment gateway in which the Direct Payment Link has been
                                upgraded. Merchants can generate QR (Quick Response) code which they can send to their
                                customers to expedite payments via mobile devices.</p>
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <div class="text-center mt-5">
        <a routerLink='/services'>
            <h4 style="display:inline; font-weight: 300" class="text-center text-black-50 mt-3 m-0 p-0">
                More Service...
            </h4>
        </a>
    </div>

</section>


<!--================About  Area =================-->

<section class="about-area area-padding-bottom">
    <div class="container">
        <div class="row align-items-center">

            <div class="col-lg-6">
                <div data-aos="fade-right">


                    <div class="area-heading mb-4 text-center">
                        <h3 style="display:inline; font-weight: 500; color: black">WORKING TOOLS</h3>
                    </div>

                    <div class="row ">
                        <div class="col-md-6 col-lg-4">
                            <div class="single-about">
                                <a href="https://dotnet.microsoft.com/" target="_block">
                                    <div class="text-center">
                                        <img src="assets/icon/asp.net.png" class="center" height="90px" width="90px"
                                            alt="Alternate Text" />

                                    </div>
                                    <div class="single-about-content text-center mt-3">
                                        <h5>.Net Core</h5>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4">
                            <div class="single-about">
                                <a href="https://www.oracle.com/index.html" target="_block">
                                    <div class="text-center">
                                        <img src="assets/icon/oracle.png" class="center" height="90px" width="90px"
                                            alt="Alternate Text" />

                                    </div>
                                    <div class="single-about-content text-center mt-3">
                                        <h5>Oracle Database</h5>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4">
                            <div class="single-about">
                                <a href="https://www.microsoft.com/en-us/sql-server/sql-server-downloads"
                                    target="_block">
                                    <div class="text-center">
                                        <img src="assets/icon/sqlServer.png" class="center" height="90px" width="90px"
                                            alt="Alternate Text" />

                                    </div>
                                    <div class="single-about-content text-center mt-3">
                                        <h5>SQL Server Database</h5>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4">
                            <div class="single-about">
                                <a href="https://www.mysql.com/" target="_block">
                                    <div class="text-center">
                                        <img src="assets/icon/MySQL.png" class="center" height="90px" width="90px"
                                            alt="Alternate Text" />

                                    </div>
                                    <div class="single-about-content text-center mt-3">
                                        <h5>MySQL Database</h5>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4">
                            <div class="single-about">
                                <a href="https://www.android.com/" target="_block">
                                    <div class="text-center">
                                        <img src="assets/icon/android.png" class="center" height="90px" width="90px"
                                            alt="Alternate Text" />

                                    </div>
                                    <div class="single-about-content text-center mt-3">
                                        <h5>Android App</h5>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4">
                            <div class="single-about">
                                <a href="https://developer.apple.com/ios/" target="_block">
                                    <div class="text-center">
                                        <img src="assets/icon/ios2.png" class="center" height="90px" width="90px"
                                            alt="Alternate Text" />

                                    </div>
                                    <div class="single-about-content text-center mt-3">
                                        <h5>IOS App</h5>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4">
                            <div class="single-about">
                                <a href="https://angular.io/" target="_block">
                                    <div class="text-center">
                                        <img src="assets/icon/angular1.jpg" class="center" height="90px" width="90px"
                                            alt="Alternate Text" />

                                    </div>
                                    <div class="single-about-content text-center mt-3">
                                        <h5>Angular</h5>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4">
                            <div class="single-about">
                                <a href="https://reactjs.org/" target="_block">
                                    <div class="text-center">
                                        <img src="assets/icon/react.png" class="center" height="90px" width="90px"
                                            alt="Alternate Text" />

                                    </div>
                                    <div class="single-about-content text-center mt-3">
                                        <h5>React</h5>
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div class="col-md-6 col-lg-4">
                            <div class="single-about">
                                <a href="https://azure.microsoft.com/en-us/c" target="_block">
                                    <div class="text-center">
                                        <img src="assets/icon/azure.png" class="center" height="90px" width="90px"
                                            alt="Alternate Text" />

                                    </div>
                                    <div class="single-about-content text-center mt-3">
                                        <h5>Microsoft Azure</h5>
                                    </div>
                                </a>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!--================About Area End =================-->

<!--================Feature  Area =================-->
<section class="feature-area area-padding bg_one pt-5">
    <div class="container">

        <div class="row align-items-center">

            <div class="offset-lg-6 col-lg-6">


                <div class="area-heading light text-center">
                    <a style="display:inline" asp-action="Product">
                        <h3 style="display:inline; font-weight: 500; color: white">PRODATA PRODUCTS</h3>
                    </a>
                </div>

                <div class="container">
                    <div class="row">
                        <div data-aos="fade-left">

                            <div class="col-">
                                <div class="single-feature d-flex">
                                    <div class="feature-icon">
                                        <img src="assets/img/icon/supply-chain.png" class="center mb-4" height="55px"
                                            width="55px" alt="Alternate Text" />
                                    </div>
                                    <div class="single-feature-content">
                                        <h5>
                                            SUPPLY CHAIN MANAGEMNET (SCM)
                                        </h5>
                                        <p>
                                            Available Features
                                            <br /> <span style="color: #00C8FF">&#10004;</span> Parameterized Inventory,
                                            Supplier Management
                                            <br /> <span style="color: #00C8FF">&#10004;</span> Supplierwise Product
                                            Costing & Comparison
                                            <br /> <span style="color: #00C8FF">&#10004;</span> Centralized Requisition
                                            Appoval
                                            <br /> <span style="color: #00C8FF">&#10004;</span> Multi Workorder against
                                            signle or multi requisition
                                            <br /> <span style="color: #00C8FF">&#10004;</span> Bynamic Inventory
                                            location Setup
                                            <br /> <span style="color: #00C8FF">&#10004;</span> Auto Notification and
                                            Dash Board
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-">
                                <div class="single-feature d-flex">
                                    <div class="feature-icon">
                                        <img src="assets/img/icon/banking.png" class="center mb-4" height="55px"
                                            width="55px" alt="Alternate Text" />
                                    </div>
                                    <div class="single-feature-content">
                                        <h5>
                                            AGENT BANKING
                                        </h5>
                                        <p>
                                            Online Based Agent Terminal Four Step Security
                                            <br /> <span style="color: #00C8FF">&#10004;</span> Bank Account, Biometric
                                            identification
                                            <br /> <span style="color: #00C8FF">&#10004;</span> SMS Notification, Paper
                                            slip Available Services
                                            <br /> <span style="color: #00C8FF">&#10004;</span> Account Opening, Cash
                                            Deposit, Cash Withdraw
                                            <br /> <span style="color: #00C8FF">&#10004;</span> Remitance Disbursement,
                                            P2P , P2B, Merchant Payment
                                            <br /> <span style="color: #00C8FF">&#10004;</span> Cheque Book Requisition,
                                            Deposit Requisition
                                            <br /> <span style="color: #00C8FF">&#10004;</span> Statement Enquery,
                                            Balance Enquire
                                            <br /> <span style="color: #00C8FF">&#10004;</span> Utility Bill Payment
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-right" style="width:100%">
                            <a routerLink='/products' class="btn ">
                                <h4 style="display:inline; font-weight: 300"
                                    class="text-center mt-3 text-white-50 m-0 p-0">
                                    More Products...
                                </h4>
                            </a>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<section class="brands-area area-padding-bottom " style="margin-top:120px">
    <div class="container">
        <h2 style="font-weight: 500" class="text-center mt-5 text-dark">
            PRODATA PORTFOLIO
        </h2>
        <div data-aos="zoom-in">


            <div class="row justify-content-center mt-5">
                <div class="col-lg-10">


                    <owl-carousel-o [options]="customOptions">

                        <ng-template carouselSlide>
                            <div class="p-1">
                                <img src="assets/img/portfolio/img17.jpg" alt="">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="p-1">
                                <img src="assets/img/portfolio/18.jpg" alt="">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="p-1">
                                <img src="assets/img/portfolio/19.jpg" alt="">
                            </div>
                        </ng-template>


                        <ng-template carouselSlide>
                            <div class="p-1">
                                <img src="assets/img/portfolio/20.jpg" alt="">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="p-1">
                                <img src="assets/img/portfolio/img3.png" alt="">
                            </div>
                        </ng-template>
                        
                        <ng-template carouselSlide>
                            <div class="p-1">
                                <img src="assets/img/portfolio/img5.png" alt="">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="p-1">
                                <img src="assets/img/portfolio/img10.png" alt="">
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="p-1">
                                <img src="assets/img/portfolio/img11.png" alt="">
                            </div>
                        </ng-template>

                        
                        <ng-template carouselSlide>
                            <div class="p-1">
                                <img src="assets/img/portfolio/img14.png" alt="">
                            </div>
                        </ng-template>

                        <ng-template carouselSlide>
                            <div class="p-1">
                                <img src="assets/img/portfolio/img15.png" alt="">
                            </div>
                        </ng-template>

                        <ng-template carouselSlide>
                            <div class="p-1">
                                <img src="assets/img/portfolio/img16.png" alt="">
                            </div>
                        </ng-template>

                        
                    </owl-carousel-o>

                </div>
            </div>
        </div>
    </div>
</section>