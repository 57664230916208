import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Common } from '../common/common';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SendEmailService {

  constructor(private http: HttpClient) {}
    
    sendEmail(emailForm: any){
      return this.http.post<any>(Common.baseUrl + '/ProDataMailSend/sendEmail', emailForm)
      .pipe(
      )};

}
